<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            'description',
            //{ ...helpers.validIcons, key: 'is_principal' }, // TODO: check if needed ?
            helpers.activeBadge
          ]"
          :config="{
            url: 'admin/property_modules',
            route: '/admin/property/modules',
            name: 'module',
            lname: 'property_modules'
          }"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Modules',
  mixins: [crudMixin]
}
</script>
