var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          'description',
          //{ ...helpers.validIcons, key: 'is_principal' }, // TODO: check if needed ?
          _vm.helpers.activeBadge
        ],"config":{
          url: 'admin/property_modules',
          route: '/admin/property/modules',
          name: 'module',
          lname: 'property_modules'
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }